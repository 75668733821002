.mat-mdc-snack-bar-container {
  .mat-mdc-simple-snack-bar {
    .mat-mdc-snack-bar-label {
      display: flex;
      align-items: center;
      gap: 10px;

      &::before {
        font-family: FontAwesome;
        font-size: 20px;
      }
    }

    .mat-mdc-button.mat-mdc-snack-bar-action {
      &:not(:disabled) {
        color: $white;
      }

      .mdc-button__label::before {
        font-family: FontAwesome;
        content: "\f00d";
      }
    }
  }

  &.os-snackbar-success {
    .mat-mdc-simple-snack-bar {
      .mat-mdc-snack-bar-label::before  {
        content: "\f05d";
        color: $success-color;
      }
    }
  }

  &.os-snackbar-error {
    .mat-mdc-simple-snack-bar {
      .mat-mdc-snack-bar-label::before  {
        content: "\f057";
        color: $danger-color;
      }
    }
  }

  &.os-snackbar-info {
    .mat-mdc-simple-snack-bar {
      .mat-mdc-snack-bar-label::before  {
        content: "\f05a";
        color: $info-color;
      }
    }
  }

  &.os-snackbar-xp, &.os-snackbar-level-up {
    .mdc-snackbar__surface {
      border: 2px solid $gold-color;
      background-color: white;
    }

    .mat-mdc-simple-snack-bar {
      .mat-mdc-snack-bar-label {
        padding: 10px 15px;
        color: $main-text-color;
        font-weight: 500;
      }

      .mat-mdc-button.mat-mdc-snack-bar-action {
        &:not(:disabled) {
          color: $main-text-color;
        }
      }
    }
  }

  &.os-snackbar-xp {
    .mat-mdc-simple-snack-bar {
      .mat-mdc-snack-bar-label {
        &::before {
          font-size: 25px;
          content: "\f005";
          color: $gold-color;
          padding-bottom: 5px;
        }
      }
    }

    .mdc-snackbar__surface {
      padding: 0;

      .mat-mdc-snack-bar-label {
        padding: 0;
      }

      .mat-mdc-button.mat-mdc-snack-bar-action {
        &:not(:disabled) {
          color: $main-text-color;
        }
      }
    }
  }

  &.os-snackbar-level-up {
    .mat-mdc-simple-snack-bar {
      .mat-mdc-snack-bar-label {
        &::before {
          font-size: 25px;
          content: "🎉";
          color: $gold-color;
          padding-bottom: 5px;
        }
      }
    }

    .mdc-snackbar__surface {
      padding: 0;

      .mat-mdc-snack-bar-label {
        padding: 0;
      }

      .mat-mdc-button.mat-mdc-snack-bar-action {
        &:not(:disabled) {
          color: $main-text-color;
        }
      }
    }
  }
}
