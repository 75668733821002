.page {
  position: relative;
  page-break-before: always;
  page-break-inside: avoid;
  page-break-after: always;
  break-after: always;
  break-before: always;
  break-inside: avoid;
}

.new-page {
  position: relative;
  page-break-before: always;
  break-before: always;
}

.avoid-break {
  page-break-inside: avoid;
  break-inside: avoid;
  position: relative;
}

@media print {
  // Class duplicated to raise specificity
  .os-no-print.os-no-print.os-no-print {
    display: none !important;
  }
}
