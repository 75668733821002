// Common
.os-container {
  margin: auto;
  overflow: auto;
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .os-container {
    width: 45%;
  }
}

.align-middle {
  vertical-align: middle;
}

// Padding
@media screen and (min-width: 950px) {
  .padding-100 {
    padding-right: 100px;
    padding-left: 100px;
  }
}

.scroll-padding {
  padding-bottom: 250px;
}

.os-form-padding {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-top: 1.5rem;

  @include mobile-start-view {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

// Margin
.margin-bottom {
  margin-bottom: 300px !important;
}

.me-20 {
  margin-right: 20px;
}

// Scroll
.no-scroll {
  overflow: hidden;
}

.scrollable {
  overflow: auto;
  max-height: 80vh;
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// Flex
.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between-top {
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.fa.align-fa-icon, .fas.align-fa-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Absolute positioning
.os-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.os-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.os-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

// Tooltips
.tooltip:has(.os-tooltip-wide), .tooltip.os-tooltip-wide {
  --bs-tooltip-max-width: 450px;
}
