@import "color-variables";

.os-mat-progress-spinner {
  box-shadow: 0 0 0 7px $main-background-color inset;
  border-radius: 50%;

  &-small {
    box-shadow: 0 0 0 5px $main-background-color inset;
    border-radius: 50%;
  }
}

.os-mat-progress-spinner-success.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #{$success-color2};
}
