// Border radius
.br-6 {
  border-radius: 6px;
}

.br-4 {
  border-radius: 4px;
}

// Border rules
.os-invalid-border {
  border: 1px solid $error-color !important;
}

.os-invalid-border:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
}

.os-border-solid {
  border-style: solid !important;
}

.os-border-dashed {
  border-style: dashed !important;
}

.os-border-dotted {
  border-style: dotted !important;
}

.os-mc-block-success {
  border: #74dd74 2px solid;
}

.os-mc-block-failure {
  border: #ff3737 2px solid;
}

.os-border-green {
  border: 0.75px solid #13b379;
}

.os-border-red {
  border: 0.75px solid #db2020;
}

.os-border-success {
  border: 1px solid #74dd74 !important;
}

.os-border-danger {
  border: 1px solid #ff3737 !important;
}

.os-green-border {
  border: 1px solid #13b379;
}

.os-light-green-border {
  border: 1px solid #b8ffb8;
}

.os-lightgreen-border {
  border: 1px solid #1cdb1c;
}

.os-border-orange {
  border: 1px solid #ffa700;
}

.os-border-light-grey {
  border: 1px solid #aaa;
}

.os-light-orange-border {
  border: 1px solid #ffe6bf;
}

.os-red-border {
  border: 1px solid #db2020;
}

.os-light-red-border {
  border: 1px solid #fed6d6;
}

.os-border-blue {
  border: 1px solid #3a5aff;
}

.os-light-grey-border {
  border: 1px solid #dfdfdf;
}

.os-dark-border {
  border: 1px solid #003049;
}

.os-border {
  border: 1px solid black;
}

.os-border-bottom-gray {
  border-bottom: 1px solid $secondary-color-gray-2;
}

.os-border-bottom-dashed-gray {
  border-bottom: 1px dashed $secondary-color-gray-2;
}

// Border color rules
.os-border-complete-color {
  border-color: #74dd74 !important;
}

.os-primary-border-color {
  border-color: $primary-color !important;
}

.os-red-border-color {
  border-color: $danger-color !important;
}


