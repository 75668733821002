/* You can add global styles to this file, and also import other style files */
@import "color-variables";
@import "breakpoints";

// Custom overrides for library styles
@import "snack-bar-material";
@import "modal-bootstrap";
@import "progress-spinner-material";
@import "tab-group-material";

// Custom css rules to use in custom components
@import "color-rules";
@import "border-rules";
@import "typography";
@import "quill";
@import "layout";
@import "blocks";
@import "buttons";
@import "printing-rules";

* {
  font-family: Jost;
}

html,
body {
  height: 100%;
}

body {
  color: $main-text-color !important;
  margin: 0;

  &.os-modal-open-no-scroll {
    overflow: hidden;
    padding-right: 15px;

    @include tablet-start-view {
      padding-right:  0;
    }
  }
}

button {
  outline: none !important;
}

ol {
  break-inside: avoid !important;
}

img {
  max-width: 100%;
}

a:hover {
  text-decoration: none !important;
  color: #0275d8 !important;
}

.os-page-background {
  background: $main-background-color;
  min-height: 100vh;
}

.s24-page-background {
  background: rgb(250, 246, 244);
}

.plyr {
  cursor: pointer !important;
}

.plyr__video-wrapper {
  background-color: #2c79ed !important;
}

.os-date {
  font-family: "Jost";
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
}

.select {
  /* background-color: transparent !important; */
  position: relative;
}

.os-tooltip .tooltip-inner {
  background-color: white;
  box-shadow: 1px 1px 10px #ccc;
}

.os-disabled {
  cursor: not-allowed;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.os-modal-large .modal-dialog {
  max-width: 1100px !important;
  width: 1100px !important;
}

.os-link-white {
  color: white !important;
  cursor: pointer;
}

.os-link-white, a {
  text-decoration: none;
}

.os-link-white:hover {
  color: white !important;
}

.os-link-white-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.os-single-toggle {
  border: 1px solid #ddd;
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.os-single-toggle:hover {
  background-color: #eee;
}

/* Tot hier */
.sub-assignment-block {
  display: inline-block;
  padding: 16px;
  text-align: center;
  border-bottom: lightgrey 1px solid;
  border-right: lightgrey 1px solid;
  cursor: pointer;
}

.sub-assignment-block:hover {
  background-color: #eee;
}

.sub-assignment-block-selected {
  background-color: #eee;
}

.ngxImageZoomContainer {
  width: auto !important;
  height: 100% !important;
}

.ngxImageZoomContainer img {
  width: 100%;
  height: auto;
  max-height: 880px;
  object-fit: contain;
  border: 1px lightgrey solid;
  padding: 12px;
}

@media screen and (max-width: 1500px) {
  .ngxImageZoomContainer img {
    max-height: 840px !important;
  }
}

input.holo {
  /* You can set width to whatever you like */
  width: 100%;
  font-size: 16px;
  margin: 0;
  padding: 8px 8px 10px 8px;
  position: relative;
  display: block;
  outline: none;
  border: none;
  color: white;
  background: #0066a9; /* fallback for old browsers */
  background: bottom left linear-gradient(#eee, #eee) no-repeat,
    bottom center linear-gradient(#eee, #eee) repeat-x,
    bottom right linear-gradient(#eee, #eee) no-repeat;
  background-size: 1px 1px, 1px 1px, 1px 1px;
}
input.holo:hover,
input.holo:focus {
  background: bottom left linear-gradient(#19c4a4, #19c4a4) no-repeat,
    bottom center linear-gradient(#19c4a4, #19c4a4) repeat-x,
    bottom right linear-gradient(#19c4a4, #19c4a4) no-repeat;
  background-size: 1px 1px, 1px 1px, 1px 1px;
}

input.holo-black {
  /* You can set width to whatever you like */
  width: 100%;
  font-size: 16px;
  margin: 0;
  padding: 8px 8px 10px 8px;
  position: relative;
  display: block;
  outline: none;
  border: none;
  color: white;
  background: black; /* fallback for old browsers */
  background: bottom left linear-gradient(darkgrey, darkgrey) no-repeat,
    bottom center linear-gradient(darkgrey, darkgrey) repeat-x,
    bottom right linear-gradient(darkgrey, darkgrey) no-repeat;
  background-size: 1px 1px, 1px 1px, 1px 1px;
}
input.holo-black:hover,
input.holo-black:focus {
  background: bottom left linear-gradient(black, black) no-repeat,
    bottom center linear-gradient(black, black) repeat-x,
    bottom right linear-gradient(black, black) no-repeat;
  background-size: 1px 1px, 1px 1px, 1px 1px;
}

/*** GOALS ***/
.bar-success .mat-progress-bar-fill::after {
  background-color: #74dd74 !important;
}

.bar-warning .mat-progress-bar-fill::after {
  background-color: #ffc107 !important;
}

.bar-warning .mat-progress-bar-buffer {
  background-color: #fde8a7 !important;
}

.bar-danger .mat-progress-bar-fill::after {
  background-color: #dc3545 !important;
}

.bar-danger .mat-progress-bar-buffer {
  background-color: #f7c8cd !important;
}

/* Smartphones */
@media screen and (max-width: 500px) {
  h1 {
    font-size: 32px !important;
  }

  h2 {
    font-size: 22px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  .modal-dialog {
    margin: 16% auto !important;
  }

  .ql-container {
    padding: 0px !important;
  }

  #fc_frame {
    max-height: 620px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-inherit {
  cursor: inherit;
}

.breadcrumb-item {
  font-size: 0.9rem;
  color: black !important;
}
.breadcrumb-item:hover {
  color: #007bff !important;
  text-decoration: underline !important;
}
.breadcrumb-item-no-link {
  font-size: 0.9rem;
  color: black !important;
}
.breadcrumb-item-no-link:hover {
  color: black !important;
  cursor: default;
}

.joyride-step__prev-container {
  display: none;
}

.joyride-step__container {
  border-radius: 20px;
  box-shadow: 0 0 0 0 transparent !important;
  width: auto !important;
  height: auto !important;
  min-width: 250px !important;
  max-width: 300px !important;
  font-family: Jost;
  z-index: 99998;
}

.joyride-step__holder {
  z-index: 99999 !important;
}

.joyride-button {
  background-color: #007bff !important;
  border-radius: 10px;
  font-family: Arvo;
  text-transform: none !important;
  font-size: 16px !important;
}

.joyride-button:hover {
  background-color: #0955a7 !important;
  border-color: transparent !important;
  color: #fff !important;
  transition: 0.5s;
}

.joyride-step__body {
  color: #646369 !important;
  padding: 20px 0 !important;
  margin: 0 15px;
  text-align: center !important;
  z-index: 99998;
}

.joyride-step__header {
  justify-content: center !important;
}

.joyride-step__counter {
  display: none;
}

:disabled {
  opacity: 0.5;
  cursor: initial !important;
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
  cursor: initial !important;
  pointer-events: none;
}

.outline-none {
  outline: none !important;
}

.highlighted {
  background-color: rgb(255, 255, 123) !important;
  border-radius: 6px;
}

.highlighted-red {
  background-color: rgb(255, 186, 186);
  border-radius: 6px;
}

.highlighted-orange {
  background-color: rgb(255, 219, 186);
  border-radius: 6px;
}

.highlighted-blue {
  background-color: rgb(176, 199, 255);
  border-radius: 6px;
}

.highlighted:hover {
  background-color: rgb(253, 241, 52) !important;
  cursor: pointer;
}

.os-admin-radio {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}

.os-admin-radio-selected {
  border: 1px solid #0b95ef;
}

/* digitalbundle */

.db-btn {
  box-shadow: 0px 5px 15px rgba(132, 132, 132, 0.1);
  border-radius: 6px;
  padding: 15px 35px;
  color: #f4f9ff;
  font-family: Arvo;
  font-size: 20px;
  border: 0;
  font-weight: 700;
}

.db-btn-dark-blue {
  background: #003049;
}

.db-btn-green {
  background: #23eaa2;
}

.db-color-dark-blue {
  color: #003049 !important;
}

.opacity-40 {
  opacity: 40%;
}

.opacity-100 {
  opacity: 100%;
}

.os-input-disabled {
  opacity: 0.5;
  cursor: initial !important;
  pointer-events: none;
}

.os-input-blue {
  background: #f4f9ff;
  border: 1px solid #aac8f7;
  box-shadow: inset 0px 3px 2px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 6px;
}

.os-text-input {
  background: #ffffff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: #718096;
  border: 1px solid #dddddd;
}

.white-shadow-block {
  background: #ffffff;
  border: 1px solid #ddebff;
  box-shadow: 0px 5px 15px rgba(180, 180, 180, 0.2);
  border-radius: 6px;

  width: 150px;
  height: 130px;
}

.text-from-text-editor > p {
  margin-bottom: 0 !important;
}

.text-from-text-editor > ul {
  overflow: hidden;
}

.ql-editor > ul {
  overflow: hidden;
}

.text-from-text-editor {
  font-family: Jost !important;
  white-space: pre-wrap;
}

@media print {
  .fc-widget-normal {
    display: none;
  }

  #CookiebotWidget {
    display: none;
  }
}

#fc_frame {
  z-index: 10000 !important;
}

/* Tooltip */
.os-tooltip {
  position: relative;
  display: inline-block;
}

.os-tooltip-inner {
  width: 100%;
  max-width: 560px;
  background-color: #ddebff;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 12px;
  z-index: 1;
  font-family: "Jost";
  font-size: 16px;
}

.os-tooltip-inner p {
  margin-bottom: 0;
}

.os-tooltip .os-tooltip-inner {
  visibility: hidden;
}

.os-tooltip:hover .os-tooltip-inner {
  visibility: visible;
}

.column-count-1 {
  column-count: 1;
}

.column-count-2 {
  column-count: 2;
}

.cdk-overlay-container {
  z-index: 999999999999 !important;
}

.os-backdrop-blurred {
  filter: brightness(75%);
  backdrop-filter: blur(4px);
}

.os-text-bubble {
  position: relative;
  background-color: $primary-color;
  color: white;
  padding: 16px;
  border-radius: 6px;
  max-width: 240px;
}

.os-text-bubble::after {
  content: '';
  position: absolute;
  top: 20px; /* Positioning the tip */
  left: -12px; /* Adjust the position of the tip horizontally */
  border-width: 0 0 12px 12px;
  border-style: solid;
  border-color: $primary-color transparent;
  display: block;
  width: 0;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: $quill-z-index;
}
