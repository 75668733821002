.btn-disabled {
  cursor: not-allowed !important;
  pointer-events: none;
}

.btn-disabled:hover {
  cursor: not-allowed !important;
}

.os-btn-success {
  background-color: #19c4a4 !important;
  color: white !important;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 0px 0px 6px 0px rgba(55, 55, 55, 0.4);
}

.os-btn-disabled {
  cursor: not-allowed !important;
  background-color: #88d3c5 !important;
}

.os-btn-disabled:hover {
  background-color: #88d3c5 !important;
}

.os-btn-success:hover {
  background-color: #1cdbb7;
}

.os-text-button {
  cursor: pointer;
  color: #0275d8 !important;
}

.os-btn-text {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-align: center;
  padding: 12px 0px;
}

.os-mobile-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 240px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px !important;
}

.os-mobile-btn-disabled {
  cursor: not-allowed !important;
  background-color: #eee !important;
  color: white !important;
}

.os-mobile-focus-btn-disabled {
  cursor: not-allowed !important;
  background-color: #b9d9ee !important;
  color: white !important;
}

.os-mobile-no-focus-btn {
  background: #ccc;
  color: white;
}

.os-mobile-no-focus-btn:hover {
  background: #dedede;
}

.os-mobile-focus-btn {
  background-color: #0b95ef;
  color: white;
}

.os-mobile-focus-btn:hover {
  background: #66c3ff;
}

.os-filter-button {
  padding: 4px 12px;
  cursor: pointer;
}

.os-filter-button-active {
  background: #3a5aff;
  box-shadow: 0px 5px 15px rgba(180, 180, 180, 0.2);
  border-radius: 6px;
  color: #ffffff;
}


.button-sent {
  padding: 7.5px 10.5px;
}

.os-btn {
  text-align: center;
  padding: 15px 35px;
  border-radius: 6px;
  font-size: 22px;
  line-height: 27px;
  border: 0px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.os-btn-primary {
  background: #23eaa2;
  box-shadow: 0px 5px 15px rgba(132, 132, 132, 0.1);
  color: #003049;
}

.os-btn-primary:hover,
.os-btn-primary:focus {
  background: #25c38a;
  box-shadow: 0px 5px 15px rgba(132, 132, 132, 0.1);
}

.os-btn-primary:disabled {
  background: #23eaa2;
  opacity: 0.4;
}
