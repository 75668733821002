$orange: #ffa700;
$yellow: #ffe300;
$green: #13b379;
$pink: #f066ff;
$light-grey: #f0f0f0;

$primary-color: #3a5aff;
$primary-color-dark: #0028f2;

$secondary-color-gray: #ced4da;
$secondary-color-gray-2: #dee2e6;
$secondary-color-gray-3: #efefef;
$secondary-color-blue: #aac8f7;

$danger-color: #db2020;
$error-color: #dc3545;
$success-color: #23eaa2;
$info-color: #f7e30c;
$success-color2: #b8ffb8;

$gold-color: #ffc64c;
$white: #fff;

// Text colors
$main-text-color: #003049;
$additional-text-color: #1a202c;
$hint-text-color: #a0aec0;
$hint-text-color2: #718096;
$lightgreen-text-color: #1cdb1c;
$red-text-color: #fc2828;

// Border colors
$input-border-color: $secondary-color-gray;

// Background colors
$main-background-color: #edf2f7;
$secondary-background-color: #f4f9ff;
$light-blue-background-color: #eff5fe;
$light-blue-background-color2: #ddebff;
$bright-blue-background-color: #2c79ed;

// Z-index
$quill-z-index: 500;
$mobile-navbar-z-index: 525;
$menu-z-index: 550;
$modal-background-z-index: 1500;
$modal-z-index: 2000;

// Book cover colors
$cover-blue: #ddebff;  
$cover-yellow:#FFF7E0;  
$cover-red: #FFE0CA;  
$cover-green:#D6FEEF;
