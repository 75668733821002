// Custom Theming for Angular Material
@use "@angular/material" as mat;
@include mat.core();
// For more information: https://material.angular.io/guide/theming
// @use "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$os-primary: (
  50 : #e8e8ff,
  100 : #c4c5ff,
  200 : #9aa0ff,
  300 : #687aff,
  400 : #3a5bff,
  500 : #0039fb,
  600 : #0030ef,
  700 : #0023e3,
  800 : #0010d8,
  900 : #0000be,
  A100 : #c4c5ff,
  A200 : #9aa0ff,
  A400 : #3a5bff,
  A700 : #0023e3,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$onlineslagen-frontend-primary: mat.m2-define-palette($os-primary);
$onlineslagen-frontend-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$onlineslagen-frontend-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$onlineslagen-frontend-theme: mat.m2-define-light-theme(
  $onlineslagen-frontend-primary,
  $onlineslagen-frontend-accent,
  $onlineslagen-frontend-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($onlineslagen-frontend-theme);


// md - xl = tablet
// xl - xxl = laptop and smaller screens
// xxx+ = big screens (22 inch+)
$grid-breakpoints: (
  xs: 0,
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1201px,
  xxl: 1600px
);

$container-max-widths: (
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1201px,
  xxl: 1600px
);

@import "node_modules/bootstrap/scss/bootstrap.scss";
