@mixin mobile-start-view() {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin tablet-start-view() {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin small-laptop-start-view() {
  @media (max-width: 1281px) {
    @content;
  }
}

@mixin laptop-start-view() {
  @media (max-width: 1601px) {
    @content;
  }
}
