.mat-mdc-tab-group {
  height: 100%;

  .mat-mdc-tab-header {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 2px solid $secondary-color-gray-2;
      bottom: 0;
    }

    .mat-mdc-tab-label-container {
      .mdc-tab {
        min-width: unset;
        padding-left: 18px;
        padding-right: 18px;
      }
    }

  }

  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }
}
