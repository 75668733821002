.os-backdrop-blurred.modal-backdrop {
  opacity: 1 !important;
  background-color: transparent !important;
}

.os-modal-offset-top.modal-dialog {
  margin-top: 250px;
}

.modal-dialog {
  max-width: 400px !important;
  display: flex;
  align-items: center;
}

.modal-xxl {
  max-width: 1250px !important;
  display: flex;
  align-items: center;
}

.modal-xl {
  max-width: 1100px !important;
  display: flex;
  align-items: center;
}

.modal-l {
  max-width: 850px !important;
  display: flex;
  align-items: center;
}

.modal-ml {
  max-width: 725px !important;
  display: flex;
  align-items: center;
}

.modal-m {
  max-width: 600px !important;
  display: flex;
  align-items: center;
}

.modal-sm {
  max-width: 500px !important;
  display: flex;
  align-items: center;
}

.image-modal .modal-dialog {
  max-width: 80% !important;
  text-align: center;
  height: 90%;
}

.image-modal .modal-content {
  flex-direction: initial !important;
  justify-content: center;
  align-items: center;
  min-height: 50%;
}
