ql-formula {
  display: block;
}

.ql-formula {
  font-size: 14px !important;
}

.text-from-text-editor img {
  max-width: 100% !important;
  height: auto;
  cursor: pointer;
}

.ql-color-\#DC3545 {
  color: #dc3545 !important;
}

.ql-color-\#74DD74 {
  color: #74dd74 !important;
}

.ql-color-\#007bff {
  color: #007bff !important;
}

/* color: rgb(195, 0, 0);
  color: rgb(255, 0, 0);
  color: rgb(255, 193, 3);
  color: rgb(255, 255, 0);
  color: rgb(146, 208, 79);
  color: rgb(6, 176, 80);
  color: rgb(6, 176, 239);
  color: rgb(4, 111, 191);
  color: rgb(1, 32, 95);
  color: rgb(98, 41, 141);

  color: #c30000;
  color: #ff0000;
  color: #ffc103;
  color: #ffff00;
  color: #92d04f;
  color: #06b050;
  color: #06b0ef;
  color: #046fbf;
  color: #01205f;
  color: #62298d;
 */

.ql-color-\#c30000 {
  color: #c30000;
}

.ql-color-\#ff0000 {
  color: #ff0000;
}

.ql-color-\#ffc103 {
  color: #ffc103;
}

.ql-color-\#ffff00 {
  color: #ffff00;
}

.ql-color-\#92d04f {
  color: #92d04f;
}

.ql-color-\#06b050 {
  color: #06b050;
}

.ql-color-\#06b0ef {
  color: #06b0ef;
}

.ql-color-\#046fbf {
  color: #046fbf;
}

.ql-color-\#01205f {
  color: #01205f;
}

.ql-color-\#62298d {
  color: #62298d;
}

.ql-color-\#06b0ef {
  color: #06b0ef;
}

.ql-color-\#FF3B53 {
  color: #ff3b53;
}

.ql-color-\#3A5AFF {
  color: #3a5aff;
}

.ql-color-\#003049 {
  color: #003049;
}

.ql-color-\#FFC43B {
  color: #ffc43b;
}

.ql-color-\#3CC5FF {
  color: #3cc5ff;
}

.ql-editor {
  line-height: 1.6 !important;
  padding: 0px;
  height: fit-content !important;
}

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-container {
  font-size: 14px !important;
  height: 600px;
  font-family: Helvetica, Arial, sans-serif !important;
}

.ql-editor h1 {
  font-size: 36px !important;
  margin-bottom: 12px !important;
}

.ql-editor h2 {
  font-size: 24px !important;
  margin-bottom: 12px !important;
}

.ql-editor h3 {
  font-size: 20px !important;
  margin-bottom: 4px !important;
}

.ql-size-huge {
  font-size: 35px;
}

.ql-size-large {
  font-size: 21px;
}

.ql-size-small {
  font-size: 10.5px;
}

.ql-font-serif {
  font-family: serif;
}

.ql-font-monospace {
  font-family: monospace;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.ql-clipboard {
  position: fixed !important;
  opacity: 0 !important;
  left: 50% !important;
  top: 50% !important;
}

.ql-tooltip {
  max-width: 90%;
  padding: 20px 12px;
  left: 20px !important;
  z-index: 999;
  position: fixed !important;
  top: 40px !important;
  margin-top: 0px !important;
}

@media screen and (min-width: 1201px) {
  .ql-float-left img {
    float: left;
    padding-right: 12px;
    background-color: white;
  }

  .ql-float-right img {
    float: right;
    padding-left: 12px;
    background-color: white;
  }
}

.ql-example-assignment-block {
  --border: 2px solid #{$primary-color};

  display: block;
  padding: 0px 20px !important;
  border-left: var(--border);
  border-right: var(--border);

  &-header-wrapper {
    display: flex;
    justify-content: end;

    > div {
      padding: 0.25rem 0.5rem;
      border: 2px solid $primary-color;
      border-bottom: none;
      border-radius: 6px;
      font-weight: 500;
      color: $primary-color;

      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &-first {
    border-radius: 6px 6px 0px 0px;
    border-top: var(--border);
  }

  &-last {
    border-radius: 0px 0px 6px 6px;
    border-bottom: var(--border);
  }

  &-only {
    border-radius: 6px;
    border: var(--border);
  }
}

li.ql-example-assignment-block {
  display: block;
  padding-left: 2.5rem !important;
  margin-left: -1.5rem;
}

h2.ql-example-assignment-block {
  margin-bottom: 0px !important;
}

h3.ql-example-assignment-block {
  margin-bottom: 0px !important;
}

.ql-formula-style {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 32px !important;
}

@media screen and (min-width: 501px) and (max-width: 1200px) {
  .ql-tooltip {
    max-width: 90% !important;
    padding: 36px !important;
  }
}

@media screen and (min-width: 1201px) {
  .ql-tooltip {
    max-width: 23% !important;
    padding-top: 20px !important;
  }
}

.mathquill4quill-mathquill-input {
  width: 53%;
}

.mathquill4quill-operator-container {
  max-width: 100%;
  margin-top: 20px;
}

.sqrt-sign {
  top: 1px !important;
  left: 1px !important;
}

.root > span > span {
  top: -10px !important;
  right: 1.5px !important;
}
