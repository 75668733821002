// Common
.os-title-header {
  overflow: auto;
  border-bottom: 1px solid lightgrey;
  padding: 12px 8px;
  margin: 0px;
}

.os-title {
  font-weight: 600;
  color: black;
}

.one-line {
  white-space: nowrap;
}

.two-lines {
  max-height: calc(2 * 1.5em);
  overflow: hidden;
  line-height: 1.5em;
}

.break-word {
  word-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.text-limit {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center;
}

.pre {
  white-space: pre-wrap !important;
}

.katex {
  white-space: normal !important;
}

.os-hover-underline:hover {
  text-decoration: underline;
}

.os-underline {
  text-decoration: underline;
}

.os-underline-dotted-blue {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #3a5aff;
}

.os-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: initial;
  word-break: break-word;
  -webkit-box-orient: vertical;
}

.os-text-nowrap {
  white-space: nowrap;
}

// Links
.os-link-hover {
  cursor: pointer;
}

.os-link-hover:hover {
  color: #3a5aff !important;
}

.os-link {
  color: #3a5aff !important;
  cursor: pointer;
  text-decoration: none;
}

.os-link:hover {
  text-decoration: underline !important;
}

a.os-link {
  text-decoration: none;
}

.os-link-underline {
  text-decoration: underline !important;
}

.os-hover-link {
  display: inline-block;
  color: #3a5aff !important;
  cursor: help;
  text-decoration: none;
  font-weight: 600 !important;
}

.os-hover-link:hover {
  text-decoration: underline !important;
}

.os-link-no-hover {
  color: #3a5aff !important;
  text-decoration: none !important;
}

.os-link-no-hover:hover {
  text-decoration: none !important;
}

.hover-os-link:hover {
  color: #3a5aff !important;
  cursor: pointer;
  text-decoration: none;
}

.os-link-visited {
  color: #c58af9 !important;
  cursor: pointer;
  text-decoration: none;
}

.os-link-visited:hover {
  color: #8e44cf !important;
}

.os-link-dark {
  color: #003049;
  cursor: pointer;
  text-decoration: none;
}

.os-link-dark:hover {
  color: #007bff !important;
}

.os-no-underline {
  text-decoration: none;

  &.hover {
    text-decoration: none;
  }
}

// Font weight
.os-font-weight-normal {
  font-weight: 400;
}

.os-medium {
  font-weight: 500 !important;
}

.os-semi-bold {
  font-weight: 500;
}

.os-line-through {
  text-decoration: line-through;
}

.os-bold {
  font-weight: 700 !important;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

// Font size
.font-4 {
  font-size: 4px !important;
}

.font-6 {
  font-size: 6px !important;
}

.font-8 {
  font-size: 8px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-34 {
  font-size: 34px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-44 {
  font-size: 44px !important;
}

.font-46 {
  font-size: 46px !important;
}

// Font family
.font-arvo {
  font-family: Arvo;
}

// Arvo
.os-h3-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 50px;
  line-height: 61.74px;
  font-weight: 700 !important;
}

.os-h3-arvo {
  font-family: "Arvo" !important;
  font-size: 50px;
  line-height: 61.74px;
}

@media screen and (max-width: 500px) {
  .os-h3-arvo-bold {
    font-size: 44px;
  }
}

.os-h4-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 42px;
  font-weight: 700 !important;
}

.os-h4-arvo {
  font-family: "Arvo" !important;
  font-size: 42px;
}

.os-h5-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 34px;
  line-height: 42px;
  font-weight: 700 !important;
}

.os-h5-arvo {
  font-family: "Arvo" !important;
  font-size: 30px;
  line-height: 42px;
}

.os-h6-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 28px;
  line-height: 35px;
  font-weight: 700 !important;
}

.os-h6-arvo {
  font-family: "Arvo" !important;
  font-size: 24px;
  font-weight: 400 !important;
}

.os-h7-arvo {
  font-family: "Arvo" !important;
  font-size: 22px;
  font-weight: 400 !important;
}

.os-h7-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 22px;
  font-weight: 700 !important;
}

.os-h8-arvo {
  font-family: "Arvo" !important;
  font-size: 18px;
  font-weight: 400 !important;
}

.os-h8-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 18px;
  font-weight: 700 !important;
}

.os-h9-arvo {
  font-family: "Arvo" !important;
  font-size: 16px;
  font-weight: 400 !important;
}

.os-h9-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 16px;
  font-weight: 700 !important;
}

.os-h10-arvo {
  font-family: "Arvo" !important;
  font-size: 14px;
  font-weight: 400 !important;
}

.os-h10-arvo-bold {
  font-family: "Arvo" !important;
  font-size: 14px;
  font-weight: 700 !important;
}

.os-arvo-bold-18 {
  font-family: "Arvo" !important;
  font-size: 18px;
  font-weight: 700 !important;
}

.os-arvo-400-16 {
  font-family: "Arvo" !important;
  font-size: 16px;
  font-weight: 400 !important;
}

.os-arvo-400-18 {
  font-family: "Arvo" !important;
  font-size: 18px;
  font-weight: 400 !important;
}

.os-arvo-400-20 {
  font-family: "Arvo" !important;
  font-size: 20px;
  font-weight: 400 !important;
}

.os-level-title {
  font-size: 22px;
  font-weight: 400 !important;
  font-family: "Arvo";
}
