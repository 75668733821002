// Text color rules

.s24-text-black {
  color: #2d3748;
}

.fa-video-camera {
  color: red;
}

.fa-line-chart {
  color: lightgreen;
}

.fa-pencil-dark {
  color: gray;
}

.os-text-primary-color {
  color: $primary-color;
}

.text-hint {
  color: $hint-text-color;
}

.os-gold {
  color: $gold-color !important;
}

.os-subtitle {
  color: #aaa;
}

.os-complete-color {
  color: #74dd74 !important;
}

.lightgrey {
  color: #aaa;
}

.os-text-primary {
  color: #25c38a;
}

.os-text-success {
  color: $success-color;
}

.os-text-dark-red {
  color: #a30000;
}

.os-text-red {
  color: #fc2828;
}

.os-text-beige {
  color: #f1c27d;
}

.os-text-secondary {
  color: #2c79ed;
}

.os-text-muted {
  color: #6b9fed;
}

.os-text-brown {
  color: #a07a0a;
}

.os-text-orange {
  color: $orange;
}

.os-text-dark-orange {
  color: #ff950c;
}

.os-text-yellow {
  color: #ffe300;
}

.os-text-green {
  color: #00e138;
}

.os-text-lightgreen {
  color: #1cdb1c;
}

.os-text-blue {
  color: #3a5aff;
}

.os-text-lightblue {
  color: #d1e4fe;
}

.os-text-dark-blue {
  color: #2f68bf;
}

.os-text-dark {
  color: $main-text-color !important;
}

.os-text-grey {
  color: #3e516c;
}

.os-text-lightgrey {
  color: $hint-text-color;
}

.os-text-light-lightgrey {
  color: #c8c7c7;
}

.os-text-gold {
  color: #ffbf00;
}

.os-green-text {
  color: #13b379;
}

.os-blue-text {
  color: #2c79ed;
}

.light-blue {
  color: #aac8f7;
}

.os-text-white {
  color: white;
}

.os-hint-text-color2 {
  color: $hint-text-color2;
}

// Background color rules
.os-success-color-faded {
  background-color: #84dbcb;
}

.os-success-color {
  background-color: #19c4a4;
}

.os-background-complete-color {
  background-color: #74dd74 !important;
}

.os-background-light-blue {
  background: $light-blue-background-color;
}

.os-background-muted-blue {
  background: $light-blue-background-color2;
}

.os-background-blue {
  background: $primary-color;
}

.os-background-bright-blue {
  background: #2c79ed;
}

.os-background-dark-blue {
  background: #2f68bf;
}

.os-background-danger {
  background: #ff0000;
}

.os-background-red {
  background: #db2020;
}

.os-background-orange {
  background: #ffa700;
}

.os-background-yellow {
  background: #ffe300;
}

.os-background-green {
  background: #13b379;
}

.os-background-lightgreen {
  background: #1cdb1c !important;
}

.os-background-light-grey {
  background: #f5f6f8;
}

.os-background-light-orange {
  background: #fff0e6;
}

.os-background-light-green {
  background: #edffed !important;
}

.os-background-light-red {
  background: #ffecec !important;
}

.os-background-dark {
  background: #003049;
}

.os-bg-dark {
  background: #0e3671;
}

.os-bg-white {
  background: white;
}
