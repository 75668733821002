.os-block-3 {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
  0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border: 1px solid #fefefe;
}

.os-block-3-hover:hover {
  cursor: pointer;
  border: 1px solid #aac8f7;
}

.os-block-dashed {
  border: 1px dashed lightgrey;
  border-radius: 6px;
}

.os-block-blue-outline {
  border: 1px solid #2c79ed;
  box-sizing: border-box;
  border-radius: 6px !important;
  padding: 5px;
}

.os-block-light-blue-outline {
  border: 1px solid #9dc3ff !important;
  box-sizing: border-box;
  border-radius: 6px !important;
  background-color: white;
}

.os-code-box {
  padding: 12px;
  border: 1px dotted lightgrey;
  border-radius: 8px;
}

.os-text-box {
  background: #f4f9ff;
  border: 1px solid #aac8f7;
  padding: 12px !important;
  border-radius: 6px;
}

.os-answer-box {
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 10px 16px;
  color: #003049;
  background: #f4f9ff;
  border: 1px solid #aac8f7;
}

.answer-box-unselected {
  background: #f4f9ff;
  border: 1px solid #003049;
  color: #003049;
}

.os-deadline-box {
  padding: 4px 12px;
  border: 1px solid #3a5aff;
  background-color: #d4f5ff33;
  border-radius: 6px;
  width: fit-content;
}

.grey-box {
  background-color: #ededed;
  border-radius: 6px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
  0px 3px 8px -1px rgba(50, 50, 71, 0.05);
}

.score-box {
  border-radius: 2px;
  width: 25px;
  height: 25px;
  text-align: center;
}

.points-box {
  background: #ffffff;
  border: 1px solid #ddebff;
  border-radius: 6px;
  padding: 6px;
}

.red-box {
  background: #fed6d6;
  border: 1px solid #ffa4a4;
}

.green-box {
  background: #b8ffb8;
  border: 1px solid #09cc31;
}

.orange-box {
  background: #ffe0ca;
  color: #ed7d2c;
  border: 1px solid #ed7d2c;
}

/* Multiple choice questions */
.os-mc-block {
  border: lightgrey 2px solid;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  background-color: white;
  cursor: pointer;
}

.os-mc-block-unselected:hover {
  background-color: #eee;
  border: #2196f3 2px solid;
}

.os-mc-block-selected {
  border: #2196f3 2px solid;
  background-color: #eee;
}

.os-mc-block-disabled {
  background-color: white !important;
  border: #ddd 2px solid !important;
  color: #ddd !important;
  cursor: not-allowed !important;
}

.os-mc-text-block {
  margin: 12px 0px;
}

/***** Responsive *****/

/* 1800+, Full hd */

/* 1300+, Small laptop */

/* 900+, Tablet */

.os-mc-tenth-block {
  margin: 1% 1% !important;
  width: 8% !important;
}

.os-mc-sixth-block {
  margin: 1% 1% !important;
  width: 14.66666% !important;
}

.os-mc-fifth-block {
  margin: 1% 1% !important;
  width: 18% !important;
}

.os-mc-quarter-block {
  margin: 1% 1% !important;
  width: 23% !important;
}

.os-mc-third-block {
  margin: 1% 1% !important;
  width: 31.3333% !important;
}

.os-mc-half-block {
  margin: 1% 1% !important;
  width: 48% !important;
}

.os-mc-full-block {
  margin: 1% 1% !important;
  width: 98% !important;
}

.desktop-block {
  display: inline-block;
}

.mobile-block {
  display: none;
}

@media screen and (max-width: 500px) {
  .desktop-block {
    display: none;
  }

  .mobile-block {
    display: inline-block;
  }

  .os-mc-tenth-block {
    margin: 1% 1% !important;
    width: 18% !important;
  }
}
